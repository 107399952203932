@tailwind base;
@tailwind components;

// Code syntax highlighting,
// powered by https://highlightjs.org
@import '~highlight.js/styles/a11y-light.css';

@import 'base';
@import 'navigation';
@import 'documentation';
@import 'search';

@tailwind utilities;
